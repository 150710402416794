import React from 'react';
import Slider from '../../common/Slider';
import { useMedia } from 'react-media';
import { device } from '../../../utils';

const Logos = ({ toContact }) => {
  const matches = useMedia({ queries: device });
  return (
    <div
      className="container"
      style={{ marginTop: matches.exsm ? '40%' : matches.sm ? ' 24%' : '6%' }}
    >
      <div className="row" style={{ margin: '24px 0' }}>
        <div className="col-12">
          <Slider toContact={toContact} />
        </div>
      </div>
    </div>
  );
};

export default Logos;
