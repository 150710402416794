import React from 'react';
import Button from '../../common/Button';
import { useMedia } from 'react-media';
import { device } from '../../../utils';
import HeroWaves from '../../common/HeroWaves';
import heroImg from '../../../img/hero.svg';
import { FormattedMessage } from 'react-intl';

const Hero = ({ toContact }) => {
  const matches = useMedia({ queries: device });

  return (
    <>
      <div style={styles.container}>
        <div className="container">
          <div className="row">
            {!matches.exsm && !matches.sm ? (
              <>
                <div
                  className="col-10"
                  style={{
                    ...styles.title,
                    fontSize: matches.exsm ? '13vw' : '64px',
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="app.hero.title.1" />
                  </span>
                  <br />
                  <div>
                    <FormattedMessage id="app.hero.title.2" />{' '}
                    {matches.md && <br />}
                    <FormattedMessage id="app.hero.title.3" />
                  </div>
                  <div style={styles.subtitle}>
                    <FormattedMessage id="app.hero.subtitle" />
                  </div>
                  <div style={styles.button}>
                    <Button
                      type="transparent"
                      onClick={toContact}
                      text={<FormattedMessage id="app.hero.start" />}
                      stylesAdded={{
                        borderRadius: '40px',
                        width: '224px',
                        height: '64px',
                        fontSize: '24px',
                      }}
                    />
                  </div>
                </div>
                <div className="col-2">
                  <img
                    style={{
                      ...styles.img,
                      top: matches.sm ? '6%' : '100px',
                    }}
                    src={heroImg}
                    alt=""
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className="col-12"
                  style={{
                    ...styles.title,
                    fontSize: matches.exsm ? '13vw' : '64px',
                    textAlign: 'center',
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="app.hero.title.1" />
                  </span>
                  <br />
                  <div>
                    <FormattedMessage id="app.hero.title.2" />{' '}
                    {matches.md && <br />}
                    <FormattedMessage id="app.hero.title.3" />
                  </div>
                  <div style={{ ...styles.subtitle, textAlign: 'center' }}>
                    <FormattedMessage id="app.hero.subtitle" />
                  </div>
                  <div style={{ ...styles.button, textAlign: 'center' }}>
                    <Button
                      stylesAdded={{
                        borderRadius: '40px',
                        width: '224px',
                        height: '64px',
                        fontSize: '24px',
                      }}
                      type="transparent"
                      onClick={toContact}
                      text={<FormattedMessage id="app.hero.start" />}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <HeroWaves />
    </>
  );
};

const styles = {
  container: {
    height: '679px',
    width: '100%',
    background: 'linear-gradient(180deg, #3399FF 0%, #00B6FF 100%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom:"80px"
  },
  title: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontSize: '64px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '86px',
    textAlign: 'left',
  },
  subtitle: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontSize: '24px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '45px',
    textAlign: 'left',
    margin: '16px 0 8px',
  },
  button: {
    textAlign: 'left',
  },
  img: {
    position: 'absolute',
    right: '0',
    top: '100px',
  },
};
export default Hero;
