import React from 'react';

const InnoLogo = () => {
  return (
    <div>
      <span style={styles.innovation}>INNOVATION</span>
      <span style={{...styles.innovation, ...styles.lab}}>LAB</span>
    </div>
  );
};

const styles = {
  innovation: {
    color: '#FFFFFF',
    fontFamily: 'Bebas Neue',
    fontSize: '30px',
    letterSpacing: '0',
    lineHeight: '36px',
  },
  lab: { color: '#C4E3FF' },
};
export default InnoLogo;
