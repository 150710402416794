import React from 'react';
import './styles.css';
import { useMedia } from 'react-media';
import { device } from '../../../utils';
import { FormattedMessage } from 'react-intl';

const MakeItSimple = ({ toContact }) => {
  const matches = useMedia({ queries: device });
  return (
    <div style={{ marginTop: matches.sm || matches.exsm ? '10%' : '6%' }} className="text-center">
      <div
        className="container"
        style={matches.exsm ? styles.clearPaddingAndMargin : {}}
      >
        <div className="row" style={{ margin: '24px 0' }}>
          <div className="col-12">
            <div className="row" style={{ margin: '24px 0' }}>
              <div className="col-12" style={styles.title}>
                
                  <FormattedMessage id="app.makeitsimple.1" />
                  <span style={{ fontWeight: 'bold' }}> <FormattedMessage id="app.makeitsimple.2" /></span>
              </div>
            </div>
            <div className="row" style={{ margin: '24px 0' }}>
              <div className="col-12" style={styles.text}>
                <FormattedMessage id="app.makeitsimple.description" />
              </div>
            </div>
            
          </div>

        </div>
      </div>
    </div>
  );
};

const styles = {
  title: {
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: '40px',
    fontWeight: '300',
    letterSpacing: '0',
  },
  text: {
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: '18px',
    letterSpacing: '0',
    lineHeight: '24px',
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
    paddingBottom: '4em',
    textAlign: 'end',
    width: '100%',
  },
  img: {
    width: '100%',
  },
  clearPaddingAndMargin: {
    padding: '0',
    margin: '0',
  },
};
export default MakeItSimple;
