import React from 'react';
import InnoLogo from '../../common/InnoLogo';
import './styles.scss';

const Header = ({ lang, setLang }) => {
  return (
    <div className='containerExt'>
      <div className="container">
        <div className="row">
          <div className="col-6 logo">
            <InnoLogo />
          </div>
          <div className="col-6 langColContainer" >
            <div className="langContainer">
              <span style={{fontWeight: lang === 'es' ? 'bold' : 'normal'}} href="#" className="link" onClick={() => setLang('es')}>
                Es
              </span>
              {' / '}
              <span style={{fontWeight: lang === 'en' ? 'bold' : 'normal'}} href="#" className="link" onClick={() => setLang('en')}>
                En
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
