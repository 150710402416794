import Hero from '../Hero';
import MakeItSimple from '../MakeItSimple';
import WelcomeAgileTeam from '../WelcomeAgileTeam';
import Logos from '../Logos';
import Contact from '../Contact';
import React, { useEffect } from 'react';
import * as Scroll from 'react-scroll';
import Oval from '../../../img/Oval.svg';
import './styles.scss';

const toContact = () => {
  Scroll.scroller.scrollTo('contact', {
    duration: 1000,
    delay: 50,
    smooth: true,
    offset: 50,
  });
};

let top = 50;
let pageYOffset = 0;
let bottom = 50;
let movement = 0.2;

const scroll = () => {
  const img1 = document.getElementById('img1');
  const img2 = document.getElementById('img2');

  if (img1 && img2) {
    if (window.pageYOffset === 0) {
      top = 50;
      bottom = 50;
      pageYOffset = 0;
    }
    if (window.pageYOffset > pageYOffset) {
      img1.style.top = top + movement + 'em';
      img2.style.bottom = bottom - movement + 'em';
      top = top + movement;
      bottom = bottom - movement;
    } else {
      img1.style.top = top - movement + 'em';
      img2.style.bottom = bottom + movement + 'em';
      top = top - movement;
      bottom = bottom + movement;
    }

    pageYOffset = window.pageYOffset;
  }
};
const Body = () => {
  useEffect(() => {
    window.addEventListener('scroll', scroll);
  }, []);

  return (
    <>
      <img
        alt=""
        id="img1"
        src={Oval}
        className="background-img__1 parallax  skyline d-none d-md-block"
      />
      <img
        id="img2"
        alt=""
        src={Oval}
        className="background-img__2 parallax  blimp d-none d-md-block"
      />
      <Hero toContact={toContact} />
      <MakeItSimple toContact={toContact} />
      <Logos toContact={toContact} />
      <WelcomeAgileTeam toContact={toContact} />
      <Contact />
    </>
  );
};

export default Body;
