import React from 'react';
import { primary } from '../../../colors';
import anii from '../../../img/anii.png';
import logo1950 from '../../../img/1950logoblanco.png';

const Footer = () => {
  return (
    <div style={styles.container}>
      <div className="container">
        <div className="row">
          <div className="col-12" style={styles.containerRow}>
            <div style={{ display: 'flex' }}>
              <div style={{...styles.logoContainer, marginRight:"1em"}}>
                <div>
                  <img alt="" src={logo1950} />
                </div>
              </div>
              <div style={styles.logoContainer}>
                <span style={styles.logoTxt}>
                  InnovationLab by
                  <span style={{ fontWeight: 'bold' }}> 1950Labs</span>
                </span>
              </div>
            </div>
            <div style={{ ...styles.logoContainer, ...styles.anii }}>
              <div>
                <img alt="" src={anii} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    backgroundColor: primary,
    position: 'absolute',
    bottom: '0',
    height: '130px',
    zIndex: '5'
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logoContainer: {
    height: '128px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  anii: {
    textAlign: 'center',
  },
  logoTxt: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontSize: '14px',

    letterSpacing: '-0.1px',
    lineHeight: '19px',
  },
};
export default Footer;
