import React from 'react';
import './styles.scss';
import Swiper from 'swiper';
import Logo1 from '../../../img/Logo1.svg';
import Logo2 from '../../../img/Logo2.svg';
import Logo3 from '../../../img/Logo3.svg';
import Logo from '../../common/Logo';
import Button from '../Button';
import { FormattedMessage } from 'react-intl';

class Slider extends React.Component {
  componentDidMount() {
    new Swiper('.blog-slider', {
      spaceBetween: 30,
      effect: 'fade',
      mousewheel: {
        invert: false,
        releaseOnEdges: true,
      },
      pagination: {
        el: '.blog-slider__pagination',
        clickable: true,
      },
    });
  }

  render() {
    const { toContact } = this.props;
    return (
      <div className="blog-slider" id="blog-slider">
        <div className="blog-slider__wrp swiper-wrapper">
          <div className="blog-slider__item swiper-slide">
            <div className="blog-slider__img">
              <Logo src={Logo1}>
                Square <span style={{ fontWeight: 'bold' }}>One</span>
              </Logo>
            </div>
            <div className="blog-slider__content">
              <div className="blog-slider__title">
                Square <span style={{ fontWeight: 'bold' }}>One</span>
              </div>
              <div className="blog-slider__text">
                <FormattedMessage id="app.logos.squareone.1"/><br/><br/>
                <FormattedMessage id="app.logos.squareone.2"/>
              </div>
              <Button type="transparent" onClick={toContact} />
            </div>
          </div>
          <div className="blog-slider__item swiper-slide">
            <div className="blog-slider__img">
              <Logo src={Logo2}>
                <span style={{ fontWeight: 'bold' }}>Makers </span>
              </Logo>
            </div>
            <div className="blog-slider__content">
              <div className="blog-slider__title">
                <span style={{ fontWeight: 'bold' }}>Makers </span>
              </div>
              <div className="blog-slider__text">
                <FormattedMessage id="app.logos.makers" />
              </div>
              <Button type="transparent" onClick={toContact} />
            </div>
          </div>

          <div className="blog-slider__item swiper-slide">
            <div className="blog-slider__img">
              <Logo src={Logo3}>
                Garage <span style={{ fontWeight: 'bold' }}>1950</span>
              </Logo>
            </div>
            <div className="blog-slider__content">
              <div className="blog-slider__title">
                Garage <span style={{ fontWeight: 'bold' }}>1950</span>
              </div>
              <div className="blog-slider__text">
                <FormattedMessage id="app.logos.garage" />
              </div>
              <Button type="transparent" onClick={toContact} />
            </div>
          </div>
        </div>
        {/* <div class="swiper-pagination"></div> */}

        <div className="blog-slider__pagination"></div>
      </div>
    );
  }
}

export default Slider;
