import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { primary } from '../../colors';

const Button = ({
  type,
  text,
  onClick,
  children,
  loading = false,
  stylesAdded = {},
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <button
      type="button"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      className="btn btn-primary"
      style={{
        ...styles.button,
        ...styles[type],
        ...(hovered ? styles[type + 'Hovered'] : {}),
        ...stylesAdded,
      }}
    >
      <div style={{ position: 'relative' }}>
        {loading && <span style={styles.content}>{children}</span>}
        <span> {text ? text : <FormattedMessage id="app.contact" />}</span>
      </div>
    </button>
  );
};

const styles = {
  button: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    border: 'none',
    borderRadius: '21.5px',
    width: '185px',
    height: '43px',
    fontSize: '14.6px',
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    letterSpacing: '1.72px',
    transition: '0.3s',
    borderStyle: 'solid',
    color: '#FFFFFF',
    borderWidth: 'thin',
  },
  transparent: {
    backgroundColor: 'transparent',
    borderColor: '#FFFFFF',
  },
  blue: {
    backgroundColor: primary,
    borderColor: primary,
  },
  blueHovered: {
    filter: 'opacity(0.7)',
  },
  transparentHovered: {
    filter: 'opacity(0.7)',
    color: primary,
    backgroundColor: '#FFFFFF',
  },
  content: {
    position: 'absolute',
    left: '12px',
  },
};

export default Button;
