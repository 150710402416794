import React, { useState } from 'react';
import './App.css';
import Header from './components/sections/Header';
import Footer from './components/sections/Footer';
import Body from './components/sections/Body';
import Waves from './components/common/Waves';
import { IntlProvider } from 'react-intl';
import messages_es from './translations/es.json';
import messages_en from './translations/en.json';

const messages = {
  es: messages_es,
  en: messages_en,
};



const App = () => {
  const [lang, setLang] = useState('en');
  return (
    <IntlProvider messages={messages[lang]} locale="en" defaultLocale="en">
      <Header lang={lang} setLang={setLang} />
      <Body />
      <Waves />
      <Footer />
    </IntlProvider>
  );
};

export default App;
