import React from 'react';

const Logo = ({ src, children }) => {
  return (
    <div style={styles.container}>
      <img alt="" src={src} />
      <span style={styles.text}>{children}</span>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    padding: '20px'
  },
  img: {},
  text: {
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: '18px',
    letterSpacing: '2.12px',
    lineHeight: '24px',
    textAlign: 'center',
  },
};
export default Logo;
