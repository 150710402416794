import React, { useState } from 'react';
import Button from '../../common/Button';
import './styles.scss';
import { useMedia } from 'react-media';
import { device } from '../../../utils';
import emailjs from 'emailjs-com';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const Contact = ({ ref }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const matches = useMedia({ queries: device });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const send = (event) => {
    event.preventDefault();
    setLoading(true);
    emailjs
      .send(
        'outlook',
        'template_QKiGH59z',
        {
          from_email: document.getElementById('email').value,
          from_name: document.getElementById('name').value,
          msg: document.getElementById('msg').value,
        },
        'user_OPoruVGvZJBQIvFpV0Y75'
      )
      .then(
        () => {
          setLoading(false);
          handleShow();
        },
        () => {
          setLoading(false);
        }
      );
  };
  return (
    <div
      name="contact"
      style={{
        ...styles.container,
        textAlign: matches.lg || matches.exlg ? 'left' : 'center',
      }}
    >
      <div className="container">
        <div className="row" style={{ margin: '0' }}>
          <div
            className="col-md-12 col-lg-6"
            style={
              matches.lg || matches.exlg
                ? styles.textContainer
                : styles.textContainerMobile
            }
          >
            <div
              style={{
                ...styles.title,
                fontSize: matches.exsm ? '13vw' : '64px',
              }}
            >
              <FormattedMessage id="app.contact.title" /> <br />
            </div>
            <div style={styles.text}>
              <span style={{ fontWeight: 'bold' }}>
                <FormattedMessage id="app.contact.discover" />
              </span>
              <br />
              <FormattedMessage id="app.contact.description" />
            </div>
          </div>

          <div className="col-md-12 col-lg-6">
            <FormattedMessage id="app.contact.name">
              {(msg) => (
                <input
                  type="name"
                  className="form-control"
                  style={styles.input}
                  id="name"
                  placeholder={msg}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="app.contact.email">
              {(msg) => (
                <input
                  type="email"
                  className="form-control"
                  style={styles.input}
                  id="email"
                  placeholder={msg}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="app.contact.message">
              {(msg) => (
                <input
                  type="msg"
                  className="form-control"
                  id="msg"
                  style={{ ...styles.input, marginBottom: '0' }}
                  placeholder={msg}
                />
              )}
            </FormattedMessage>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  margin: '24px 0',
                  textAlign: matches.lg || matches.exlg ? 'right' : 'center',
                }}
              >
                <Button
                  loading={loading}
                  type="blue"
                  text={<FormattedMessage id="app.contact.send" />}
                  onClick={(event) => {
                    send(event);
                  }}
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <div style={styles.modalContent}>
          <div style={{ ...styles.text, fontSize: '18px' }}>
            <FormattedMessage id="app.contact.success.message" />
          </div>
          <div style={{ marginTop: '24px' }}>
            <Button type="blue" text={'ok'} onClick={handleClose} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    margin: '6% 0 6%',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingRight: '15%',
  },
  textContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '5%',
  },
  title: {
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: '68.8px',
    fontWeight: '800',
    letterSpacing: '0',
    lineHeight: '86px',
    margin: '24px 0',
  },
  text: {
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: '14.62px',
    letterSpacing: '-0.1px',
    lineHeight: '20px',
  },
  input: {
    boxSizing: 'border-box',
    height: '64px',
    border: '0.86px solid #578CB8',
    borderRadius: '8.6px',
    margin: '8px 0',
  },
  modalContent: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
export default Contact;
