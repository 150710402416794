import React from 'react';
import './styles.css';

const HeroWaves = () => {
  return (
    <svg
      className="editorial-bis"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 24 150 28 "
      preserveAspectRatio="none"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 
    58-18 88-18s
    58 18 88 18 
    58-18 88-18 
    58 18 88 18
    v44h-352z"
        />
      </defs>

      <g className="parallax2">
        <use xlinkHref="#gentle-wave" x="50" y="0" fill="#b4e9ff" />
      </g>
      <g className="parallax3">
        <use xlinkHref="#gentle-wave" x="50" y="9" fill="#61d0ff" />
      </g>
      <g className="parallax4">
        <use xlinkHref="#gentle-wave" x="50" y="6" fill="#00b6ff" />
      </g>
    </svg>
  );
};

export default HeroWaves;
