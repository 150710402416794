import React from 'react';
import welcome from '../../../img/welcome.jpg';
import Button from '../../common/Button';
import { useMedia } from 'react-media';
import { device } from '../../../utils';
import { FormattedMessage } from 'react-intl';

const WelcomeAgileTeam = ({ toContact }) => {
  const matches = useMedia({ queries: device });
  return (
    <div style={{ marginTop: '6%' }}>
      <div
        className="container"
        style={
          matches.exsm || matches.sm || matches.md
            ? styles.clearPaddingAndMargin
            : {}
        }
      >
        {matches.lg || matches.exlg ? (
          <div className="row" style={{ margin: '24px 0' }}>
            <div className="col-7" style={styles.textContainer}>
              <div className="row" style={{ margin: '24px 0' }}>
                <div className="col-12" style={styles.title}>
                  <FormattedMessage id="app.welcome.title.1" /> <br />
                  <span style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="app.welcome.title.2" />
                  </span>
                </div>
              </div>
              <div className="row" style={{ margin: '24px 0' }}>
                <div className="col-12" style={styles.text}>
                  <FormattedMessage id="app.welcome.description" />
                </div>
              </div>
              <div className="row" style={{ margin: '24px 0' }}>
                <div className="col-12">
                  <Button
                    type="transparent"
                    onClick={toContact}
                    text={<FormattedMessage id="app.contact.title" />}
                  />
                </div>
              </div>
            </div>
            <div className="col-5">
              <div style={styles.imgContainer}>
                <img style={styles.img} src={welcome} alt="" />
              </div>
            </div>
          </div>
        ) : (
          <div className="row" style={{ margin: '24px 0' }}>
            <div className="col-12" style={{ ...styles.textContainerMobile }}>
              <div className="row" style={{ margin: '24px 0' }}>
                <div className="col-12" style={styles.title}>
                  <FormattedMessage id="app.welcome.title.1" /> <br />
                  <span style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="app.welcome.title.2" />
                  </span>
                </div>
              </div>
              <div className="row" style={{ margin: '24px 0' }}>
                <div className="col-12" style={styles.text}>
                  <FormattedMessage id="app.welcome.description" />
                </div>
              </div>
              <div className="row" style={{ margin: '24px 0' }}>
                <div className="col-12">
                  <Button
                    type="transparent"
                    onClick={toContact}
                    text={<FormattedMessage id="app.contact.title" />}
                  />
                </div>
              </div>
              <div className="row" style={{ margin: '24px 0' }}>
                <div className="col-12">
                  <img style={styles.imgMobile} src={welcome} alt="" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  textContainer: {
    borderRadius: '20px',
    background: 'linear-gradient(180deg, #3399FF 0%, #00B6FF 100%)',
    width: '100%',
    height: '100%',
    padding: '2%',
    paddingRight: '10%',
  },
  textContainerMobile: {
    background: 'linear-gradient(180deg, #3399FF 0%, #00B6FF 100%)',
    width: '100%',
    height: '100%',
    padding: '2%',
  },
  title: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontSize: '40px',
    fontWeight: '300',
    letterSpacing: '0',
  },
  text: {
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    fontSize: '18px',
    letterSpacing: '0',
    lineHeight: '24px',
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  img: {
    width: '130%',
    position: 'relative',
    right: '30%',
    height: '80%',
  },
  imgMobile: {
    width: '100%',
  },
  clearPaddingAndMargin: {
    padding: '0',
    margin: '0',
    maxWidth: 'inherit',
  },
};
export default WelcomeAgileTeam;
